import React from "react";
import "./App.scss";
import "../src/assets/MastertaketIcon/css/fontello.css";
import "leaflet/dist/leaflet.css";
import { Routes, Route, Navigate } from "react-router-dom";

import { Suspense } from "react";
import { PublicRoutes, PrivateRoutes } from "./routes";
import LayoutBox from "./components/LayoutBox";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import { antConfig } from "./utils/ant-config";

function App() {
  const { venderData } = useSelector((state) => state.vendorDetails);
  // console.log("vendorData", venderData);

  return (
    <ConfigProvider theme={antConfig(venderData)}>
      <Suspense fallback={<div>App is Loading...</div>}>
        <Routes>
          <Route path="/" exact element={<LayoutBox />}>
            {PublicRoutes.map(({ path, exact, component: Component }) => {
              return (
                <Route
                  key={path}
                  path={path}
                  exact={exact}
                  element={<Component></Component>}
                />
              );
            })}
          </Route>

          {/* <Route path="/" exact element={<LayoutBox />}>
          {PrivateRoutes.map(({ path, exact, component: Component }) => {
            return authToken && isLogin === "1" ? (
              <Route
                key={path}
                path={path}
                exact={exact}
                element={<Component></Component>}
              />
            ) : (
              <Route
                key={path}
                path={path}
                exact={exact}
                element={<Navigate to="/signin" />}
              />
            );
          })}
        </Route> */}
        </Routes>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
